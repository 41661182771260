<template>
    <b-modal dialog-class="modal-pax" ref="modal-suggestion" hide-footer id="paxModalSelection">
        <template v-slot:modal-title>
            {{ $t("pax.suggestion_pax") }}
        </template>

        <div v-if="processing">
        	<LoadingSpinner class="col-12" />
        </div>

       	<template v-else>
	    	<div class="stallionGrade" v-for="stallion in stallions_suggestion" :key="stallion.horse_id" :class="colorGrade(stallion.horse_id)">
				<div class="row no-gutters align-items-center" >
					<div class="col-5">
						<div class="stallionName">{{ stallion.horse_nom }}</div>
					</div>
					<div class="col-7 text-center">
						<span class="bullet" v-for="critere in criteres" :class="getColorBullets(stallion.horse_id, critere.criteres_id)[0]" :key="critere.criteres_id"><font-awesome-icon :icon="['fas', 'circle']"/></span>
					</div>
				</div>
			</div>

			<div class="text-center mt-2">
				<button class="btn btn-pax" @click="sendStallionsSelected">{{ $t('pax.modifier_selection') }}</button>
			</div>
		</template>
    </b-modal>
</template>

<script type="text/javascript">
	import PaxMixin from "@/mixins/Pax.js"
	import _cloneDeep from 'lodash/cloneDeep';
	import Navigation from "@/mixins/Navigation.js"

	export default {
		name: 'PaxSuggestion',
		mixins: [PaxMixin, Navigation],
		props: {
			criteres: {
				type: Array,
				default: () => ( [] )
			},
			stallions: {
				type: Array,
				default: () => ( [] )
			},
			stallions_available: {
				type: Array,
				default: () => ( [] )
			},
			mare: {
				type: Object,
				default: () => ( null )
			},
			suggestion: {
				type: Array,
				default: () => ( [] )
			}
		},
		data () {
			return {
				stallions_suggestion: [],
				recap: [],
				processing: false
			}
		},

		methods: {
			openModal() {
				this.$refs['modal-suggestion'].show()
				this.calculSuggestion()
			},

			getColorBullets(stallion_id, criteres_id) {
				if(this.recap[stallion_id] === undefined) {
					return false
				}

				let criteres_local = _cloneDeep(this.criteres)

				if(criteres_id != null) {
					criteres_local = criteres_local.filter(elem => {
						return elem.criteres_id == criteres_id
					})
				}

				let tab_bullets = []
				
				for (let index = 0; index < criteres_local.length; index++) {
					const element = criteres_local[index];
					
					if(parseInt(this.recap[stallion_id][element.criteres_id]) < element.criteres_red) {
						tab_bullets.push('red')
					}
					else if(parseInt(this.recap[stallion_id][element.criteres_id]) >= element.criteres_green) {
						tab_bullets.push('green')
					}
					else if(parseInt(this.recap[stallion_id][element.criteres_id]) >= element.criteres_red && parseInt(this.recap[stallion_id][element.criteres_id]) < element.criteres_green) {
						tab_bullets.push('orange')
					}
				}

				return tab_bullets
			},

			colorGrade(stallion_id) {
				// Pour les ronds : on fait un premier essai avec vert si compatibilité supérieure à 50%, orange si 30 à 50% et rouge si inférieure à 30%
				// Pour les fonds de cartouche étalons
				// Vert foncé si tous les ronds sont verts
				// Vert clair si que des ronds verts et 1 où 2 oranges et 0 rouges
				// Jaune si 3 oranges ou plus et 0 rouges ou 1 rouge et 1 ou 2 oranges
				// Orange si 1 rouge et 3 oranges ou plus ou 2 rouges et 1 ou 2 oranges
				// Rouge si 2 rouges et 3 oranges ou plus ou plus de 2 rouges

				const tab_bullets = this.getColorBullets(stallion_id)
				if(!tab_bullets) {
					return 'noir'
				}
				const nb_elems = tab_bullets.length
				const nb_elems_green = tab_bullets.filter((elem) => elem == 'green').length
				const nb_elems_red = tab_bullets.filter((elem) => elem == 'red').length
				const nb_elems_orange = tab_bullets.filter((elem) => elem == 'orange').length


				if(nb_elems == nb_elems_green) {
					return 'dark_green'
				}
				else if(nb_elems_green >= 1 && nb_elems_orange <= 2 && nb_elems_red == 0) {
					return 'green'
				}
				else if((nb_elems_red == 2 && nb_elems_orange >= 3) || nb_elems_red > 2) {
					return 'red'
				}
				else if((nb_elems_red == 1 && nb_elems_orange >= 3) || (nb_elems_red == 2 && nb_elems_orange <= 2)) {
					return 'orange'
				}
				else if((nb_elems_orange >= 3 && nb_elems_red == 0) || (nb_elems_red == 1 && nb_elems_orange <= 2)) {
					return 'yellow'
				}

				return 'noir'
			},

			async calculSuggestion() {
				this.$refs['modal-suggestion'].show()
				this.processing = true

				const country = this.getConfig('country')
				const stallions_ids = this.stallions.map(stallion => stallion.horse_id)

				// const all_stallions = await this.getStallions()
				const all_stallions = this.stallions_available
				const stallions = all_stallions.filter(stallion => {
					let countries = stallion.horse_pays
					return countries.indexOf(parseInt(country.id)) > -1 && !stallions_ids.includes(stallion.horse_id) && !stallion.categorie.includes('COLLECTION')
				})

				this.recap = await this.paxCalculCompatiblite(stallions, this.criteres)
				const notation = await this.getPaxColor(this.recap, this.criteres)
				const notation_sort = Object.entries(notation).sort((a,b) => b[1]-a[1]).slice(0, 5)

				this.stallions_suggestion = []
				notation_sort.forEach(stallion => {
					this.stallions_suggestion.push(stallions.find(s => s.horse_id == stallion[0]))
				})
				this.processing = false
			},

			sendStallionsSelected() {
				this.$emit('update:suggestion', this.stallions_suggestion)
				this.$refs['modal-suggestion'].hide()
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35')
		}
	}

</script>